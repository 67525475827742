import React from "react";
import { Animated } from "react-animated-css";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import { DivRow } from "../../tools/identificadorQRCliente/PaginaSolicitudRetiro";
import ButtonSalta from "./ButtonSalta";
import { estilosProv } from "../../configProvincias";

const ExtractionInProgressSalta = ({
  solicitud,
  direccionLoteria,
  clickCancelarSolicitud,
  clickGenerarQRSolRetiro,
}) => {
  const color = process.env.REACT_APP_MANIFEST_COLOR;
  const stylesByOrg = estilosProv[process.env.REACT_APP_COD_ORGANIZACION];
  const extractionType = "manually";

  return (
    <DivMain>
      <DivContainer>
        {/* dates info */}
        <div className="col-12">
          <span className="m-bold">Fecha solicitud:</span>{" "}
          {solicitud.fechaPedido?.substring(0, 10) ?? "-"}
        </div>
        <div className="col-12">
          <span className="m-bold">Vigente hasta:</span>{" "}
          {solicitud.fechaCaducidad?.substring(0, 10) ?? "-"}
        </div>
        {/* money info */}

        <DivRow className="">
          <div
            className="col-12"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Animated
              animationIn="zoomIn"
              animationInDelay={800}
              animationInDuration={500}
            >
              <div>
                <span
                  style={{ color: color }}
                  className="iconify"
                  data-icon="cil:check"
                  data-inline="false"
                  data-width="60px"
                ></span>
              </div>
            </Animated>

            <div className="">
              {solicitud != null &&
                (solicitud.diasEsperaRetiro == null ||
                  solicitud.diasEsperaRetiro === 0) && (
                  <div>
                    <h5
                      className="m-bold"
                      style={{ margin: 0, color: stylesByOrg.success }}
                    >
                      Ya podes retirar{" "}
                    </h5>
                    <h5
                      className="m-bold"
                      style={{ margin: 0, color: stylesByOrg.success }}
                    >
                      <NumberFormat
                        value={solicitud.importe}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        isNumericString={true}
                      />
                    </h5>
                  </div>
                )}
            </div>
            <div className="">
              {solicitud != null &&
                solicitud.diasEsperaRetiro != null &&
                solicitud.diasEsperaRetiro === 1 && (
                  <div>
                    <h5
                      className="m-bold"
                      style={{ margin: 0, color: stylesByOrg.success }}
                    >
                      Podrás retirar{" "}
                    </h5>
                    <h5
                      className="m-bold"
                      style={{ margin: 0, color: stylesByOrg.success }}
                    >
                      <NumberFormat
                        value={solicitud.importe}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        isNumericString={true}
                      />
                    </h5>
                  </div>
                )}
            </div>
            <div className="">
              {solicitud != null &&
                solicitud.diasEsperaRetiro != null &&
                solicitud.diasEsperaRetiro > 1 && (
                  <div>
                    <h5
                      className=" m-bold"
                      style={{ margin: 0, color: stylesByOrg.success }}
                    >
                      Podrás retirar{" "}
                    </h5>
                    <h5
                      className=" m-bold"
                      style={{ margin: 0, color: stylesByOrg.success }}
                    >
                      <NumberFormat
                        value={solicitud.importe}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        isNumericString={true}
                      />
                    </h5>
                  </div>
                )}
            </div>
          </div>
        </DivRow>
        {/* extra info */}
        {extractionType === "manually" && (
          <DivRow className="">
            <div className="col-12">
              {solicitud.retiraEnLoteria != true && (
                <span style={{ color: "#A8A7A6", fontSize: ".9rem" }}>
                  acercándote a una agencia oficial
                </span>
              )}
            </div>
            <div className="col-12">
              {solicitud.retiraEnLoteria === true && (
                <span style={{ color: "#A8A7A6", fontSize: ".9rem" }}>
                  dirigiéndote a la{" "}
                  <b>Loteria de {process.env.REACT_APP_NOMBRE_PROVINCIA}</b>
                  <br /> {direccionLoteria}
                </span>
              )}
            </div>
            <div className="col-12">
              {solicitud != null &&
                solicitud.diasEsperaRetiro != null &&
                solicitud.diasEsperaRetiro > 1 && (
                  <span style={{ color: "#A8A7A6", fontSize: ".9rem" }}>
                    a los {solicitud.diasEsperaRetiro} días hábiles posterior
                    <br></br>a la fecha de solicitud
                  </span>
                )}
            </div>
          </DivRow>
        )}
        {/* actions */}
        <DivRow className="">
          <div
            className="col-12"
            style={{
              display: "flex",
              justifyContent: `${
                extractionType === "manually" ? "space-between" : "center"
              }`,
            }}
          >
            {extractionType === "manually" && (
              <ButtonSalta
                variant="success"
                id="botonGenerarQR"
                data-toggle="modal"
                data-target="#qrModal"
                onClick={clickGenerarQRSolRetiro}
              >
                Generar QR
              </ButtonSalta>
            )}
            <ButtonSalta
              variant="danger"
              type="button"
              className={"btn btn-danger"}
              data-toggle="modal"
              onClick={() => {
                clickCancelarSolicitud();
              }}
            >
              Cancelar
            </ButtonSalta>
          </div>
        </DivRow>

        {extractionType === "manually" && (
          <DivRow className="">
            <p style={{ color: "#A8A7A6", fontSize: ".8rem" }}>
              Cuando estés en la agencia generá tu
              <br />
              <span className="m-bold">QR de identificación</span>, el mismo te
              será solicitado.
            </p>
          </DivRow>
        )}
      </DivContainer>
    </DivMain>
  );
};

export default ExtractionInProgressSalta;

const DivMain = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const DivContainer = styled.div`
  width: auto;
`;
