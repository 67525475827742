import React from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import EfectoCargando, { DivCargando } from "../EfectoCargando";
import { estilosProv } from "../../configProvincias";
import { FormGroup, Input, Label } from "reactstrap";
import ExtractionsSalta from "../../components/salta/ExtractionsSalta";
const size = window.innerWidth;

const PaginaSolicitudRetiro = ({
  logoRetiro,
  cargando,
  saldo,
  grabandoSolicitud,
  montoConDecimales,
  agenciaRetiros,
  handleInputBlur,
  setmontoConDecimales,
  clickGenerarSolicitud,
  logueado
}) => {
  const colorProv = estilosProv[process.env.REACT_APP_COD_ORGANIZACION].fondo;

  if (["23", "53"].includes(process.env.REACT_APP_COD_ORGANIZACION))
    return (
      <ExtractionsSalta
        cargando={cargando}
        saldo={saldo}
        grabandoSolicitud={grabandoSolicitud}
        montoConDecimales={montoConDecimales}
        handleInputBlur={handleInputBlur}
        setmontoConDecimales={setmontoConDecimales}
        clickGenerarSolicitud={clickGenerarSolicitud}
        logueado={logueado}
      />
    );

  return (
    <div>
      {!grabandoSolicitud ? (
        <div className="container">
          <DivRow className="row justify-content-center"></DivRow>

          {cargando === "INICIO" || cargando === "EJECUTANDO" ? (
            <DivRow className="row justify-content-center">
              <DivCargando className="col-12">
                <EfectoCargando />
              </DivCargando>
            </DivRow>
          ) : (
            <DivRow className="row justify-content-center">
              <div className="col-6">
                <H6SaldoTexto>Saldo para extracción</H6SaldoTexto>

                <div
                  className="p-2 rounded w-92 mx-auto"
                  style={{
                    border: "solid",
                    borderColor: "grey",
                    borderWidth: "1px",
                  }}
                >
                  {cargando === "FIN" && (
                    <H5Saldo className="mt-2 m-heavy text-success bg-light w-90 pt-1 pb-1 rounded mx-auto">
                      <NumberFormat
                        value={saldo}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        isNumericString={true}
                      />
                    </H5Saldo>
                  )}
                </div>
              </div>

              <div className="col-6">
                {cargando !== "EJECUTANDO" && grabandoSolicitud === false && (
                  <div>
                    <h6>
                      {" "}
                      Monto a <span className="m-bold">retirar</span>{" "}
                    </h6>
                    <div
                      className="p-3 rounded w-92 mx-auto"
                      style={{
                        border: "solid",
                        borderColor: "grey",
                        borderWidth: "1px",
                      }}
                    >
                      <b>
                        <span style={{ fontSize: "20px" }}>$ </span>
                      </b>
                      <NumberFormat
                        type="text"
                        autoComplete="off"
                        maxLength={13}
                        isNumericString={true}
                        onChange={handleInputBlur}
                        decimalScale={2}
                        id="idMontoSolicitud"
                        name="fMontoSolicitud"
                        style={{
                          width: "75%",
                          border: "none",
                          fontWeight: "bold",
                          textAlign: "center",
                          height: "32px",
                        }}
                        displayType={"input"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        onValueChange={(values) => {
                          setmontoConDecimales(values.floatValue);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {grabandoSolicitud && (
                <div>
                  <b>
                    <span style={{ fontSize: "20px" }}>
                      <NumberFormat
                        value={montoConDecimales}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        isNumericString={true}
                      />
                    </span>
                  </b>
                </div>
              )}
            </DivRow>
          )}
          {cargando !== "EJECUTANDO" && (
            <DivRow className="row justify-content-center">
              {agenciaRetiros != null && agenciaRetiros.codigo && (
                <div className="col-12" style={{ fontSize: "12px" }}>
                  Tu agencia amiga es:
                  <h6 className="mt-1 mb-1 m-bold">
                    Agencia n°{" "}
                    {agenciaRetiros != null ? agenciaRetiros.descripcion : ""}{" "}
                  </h6>
                  {agenciaRetiros != null ? agenciaRetiros.direccion : ""}{" "}
                  {agenciaRetiros != null ? "-" : ""}
                  {agenciaRetiros != null ? agenciaRetiros.localidad : ""}
                </div>
              )}
              {agenciaRetiros != null && !agenciaRetiros.codigo && (
                <div>
                  <div></div>en la <b>Agencia amiga </b>
                  <br></br>
                </div>
              )}
              {!agenciaRetiros && (
                <div>
                  <div></div>en la <b>Agencia amiga </b>
                  <br></br>
                </div>
              )}

              <br></br>
            </DivRow>
          )}

          {cargando !== "EJECUTANDO" && grabandoSolicitud === false && (
            <DivRow className="row justify-content-center">
              <div className="col-12">
                <button
                  id="botonGenerarSolicitud"
                  className={
                    "btn btn-success p-2 pl-3 pr-3 bg-btn-extraccion-prov-" +
                    process.env.REACT_APP_COD_ORGANIZACION
                  }
                  disabled={
                    montoConDecimales === undefined ||
                    0 >= montoConDecimales ||
                    montoConDecimales > saldo
                  }
                  onClick={clickGenerarSolicitud}
                >
                  <h5 className="mb-0">Enviar</h5>
                </button>
              </div>
            </DivRow>
          )}
        </div>
      ) : (
        <DivRow className="row justify-content-center">
          <DivCargando className="col-12">
            <EfectoCargando />
          </DivCargando>
          <DivColgrabSoli className="col-12">
            <span className="op-text animate-flicker">
              <b>Grabando solicitud ...</b>
            </span>
          </DivColgrabSoli>
        </DivRow>
      )}
    </div>
  );
};

export default PaginaSolicitudRetiro;

export const DivRow = styled.div`
  text-align: center;
  margin-top: 4vh;
`;

export const DivColRetiro = styled.div`
  display: contents;
`;
export const H4Retiro = styled.h4`
  align-self: center;
  font-family: "Mont-Bold" !important;
`;

export const DivColgrabSoli = styled.div`
  margin-top: 3vh;
`;

export const H5Saldo = styled.h5`
  white-space: nowrap;
  width: fit-content !important;
`;

export const H6SaldoTexto = styled.h6`
  white-space: nowrap;
`;
